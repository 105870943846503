import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlataformaImagem from '../../assets/images/plataforma.png';
import WaveVector from '../../assets/images/WaveVector.png';
import './DadosPlataformaStyle.scss';
import {iEstagioCor, iWell} from '../../@types/plataforma.type';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const Plataforma: React.FC<{
    rodape?: boolean,
    plataforma: any, 
    plataformaSections: any,
    well: iWell,
    loading: boolean}> = ({rodape=true, plataforma, plataformaSections, well, loading}) => {
  const cementingColor = '#2ED1C8';
  const casingColor = '#145974';
  const drillingColor = '#702C92';
  const transparente = 'transparent';

  const [cemPorCento, setCemPorCento] = React.useState<number>(0)

  const getColorStatus = (status: string | unknown) => {
    switch (status) {
      case 'drilling':
        return drillingColor;
      case 'casing':
        return casingColor;
      case 'cementing':
        return cementingColor;
      default:
        return transparente;
    }
  }

  const getLinearGradient = (cores: iEstagioCor[], height: number) => {
    // monta o css linear de acordo com status de cada estagio, fazendo a barra do grafico poder ter mais de uma cor
    // para manter cor solida e não ficar gradiente, ele vai da porcentagem anterior ate a atual do estagio, para depois mudar
    // ex: drilling em 50% casing 75% => linear-gradient(corDrilling 0%, corDrilling 50%, corCasing 50% corCasing 75%)

    const drilling = cores.find(c => c.estagio === 'drilling') || {estagio: '', altura: 0}
    const casing = cores.find(c => c.estagio === 'casing') || {estagio: '', altura: 0}
    const cementing = cores.find(c => c.estagio === 'cementing') || {estagio: '', altura: 0}
    
    let gradient = `
      linear-gradient(
        to bottom, 
    `
    gradient += `
      ${getColorStatus(cementing.estagio)} 0%, 
      ${getColorStatus(cementing.estagio)} ${cementing.altura}%`

    // logica para que quando for 100% possa cobrir toda a barra
    if (cementing.altura < 100){
      gradient += `,
        ${getColorStatus(casing.estagio)} ${cementing.altura}%,
        ${getColorStatus(casing.estagio)} ${casing.altura}%`
  
      if (casing.altura < 100) {
        gradient += `,
          ${getColorStatus(drilling.estagio)} ${casing.altura}%,
          ${getColorStatus(drilling.estagio)} ${drilling.altura}%`

          if (drilling.altura < 100) {
            gradient += `,
              ${getColorStatus('transparent')} ${drilling.altura}%,
              ${getColorStatus('transparent')} ${100}%`
          }
      }
    }

    gradient += ')'
    const css = {
      height: `${height}%`,
      background: gradient,
    }
    return css
  }

  const getSectionIndex = (inversePlataformaSections: any, estg: any) => {
  return inversePlataformaSections.findIndex((section: {id: number}) => {
    const estagioId = estg?.[0]?.sessao?.replace("Section object (", '').replace(')','') || null
    return Number(estagioId)===section.id
  })
}

  React.useEffect(()=>{
    if(plataformaSections.length>0){
      const ultimo = plataformaSections[plataformaSections.length-1].mdbottom
      setCemPorCento(ultimo)
    }
  }, [plataformaSections, plataforma])
  React.useEffect(()=>{
  },[well])

  return (
    <section className="plataforma bg-cinza flex-20 mx-3 p-1 d-flex flex-column p-relative">
      {/* {loading?
      <div style={{position: "absolute", right: 10, top: 10}}><CircularProgress size="15px" /></div>: <></>} */}
      <div className='flex-1 d-flex' >
        <div className="flex-2 d-flex flex-column">
          <div className="flex-1 d-flex flex-column">
            <div className="flex-40 imagem-plataforma">
              <img src={PlataformaImagem} alt="Plataforma" />
              {/* <img src={WaveVector} alt="Imagem onda" className="div-agua" /> */}
            </div>
            <div className="flex-1 flex-center" >
              <div className="d-flex div-graficos" style={{width:'80%'}}>
                {/* Barras verticais lado esquerdo */}
                {plataforma?.estagios?.map((estg: any, index: any) => {
                  const indice = getSectionIndex(plataformaSections, estg)
                  // se não econtra indice, retorna uma div vazia
                  const porcentagem = indice >= 0 ? ((plataformaSections[indice].mdbottom*100) / cemPorCento) : null
                  return porcentagem ? 
                  (
                    <div key={index} style={getLinearGradient(estg, porcentagem)}> </div>
                  ) : 
                  (<div key={index} style={{border: 'none'}}></div>)
                })}

                {/* Barra branca da broca */}
                <div className="barraBroca" style={{
                  height: `${cemPorCento>0 ? plataforma.current_bit_depth*100/cemPorCento : 0}%`, 
                  }}> </div>

                {/* Barras verticais lado direito - inverso */}
                {plataforma?.estagios?.slice().reverse().map((estg: any, index: any) => {
                  // inverte o array pra pegar a posição
                  const inversePlataformaSections = plataformaSections.map((section: any)=> section).reverse()
                  const indice = getSectionIndex(inversePlataformaSections, estg)
                  // se não econtra indice, retorna uma div vazia
                  const porcentagem = indice >= 0 ? ((inversePlataformaSections[indice].mdbottom*100) / cemPorCento) : null
                  return porcentagem ? 
                  (
                    <div key={index} style={getLinearGradient(estg, porcentagem)}> </div>
                  ):
                   (<div key={index} style={{border: 'none'}}></div>)}
                )}
              </div>
            </div>
          </div>
          
        </div>
        <div className="flex-1 d-flex flex-column">
          
          <div className="flex-1 text-left div-legendas d-flex flex-column">
            
            <div className="d-flex flex-column div-legendas-top" >
              <div className="borda" style={{flex: '1'}} >
                <span className="text-white font-xsmall">Air Gap {well?.airgap}m</span>
              </div>
              {/* <div className="borda" >
                <span className="text-white font-xsmall">Water Depth {well?.waterdepth}m</span>
              </div> */}
            </div>

            <div className="flex-1 d-flex flex-column">
              {plataformaSections?.map((section:any, index: number)=>{
                const primeiro = index===0
                const anterior = primeiro ? 0 : plataformaSections[index-1].mdbottom
                const porcentagem = (section.mdbottom-anterior) / cemPorCento

                return (
                  cemPorCento ? 
                  <div className="borda" style={{flex: porcentagem}} key={index}>
                    <div className='flex-1 d-grid'>
                      <span className="text-white font-xsmall" style={{lineHeight: '.5rem'}}>{section.name} {section.mdbottom}m</span>
                    </div>
                  </div> : 
                  <span key={index}></span>
                )}
              )}
            </div>
            
          </div>
        </div>
      </div>
      <div className='m-1 div-cor-legenda d-flex text-white'>
        <span className='circulo drillingColor'></span>
        <span>Drilling</span>
        <span><ArrowForwardIcon style={{fontSize: 10}}/></span>
        <span className='circulo casingColor'></span>
        <span>Casing</span>
        <span><ArrowForwardIcon style={{fontSize: 10}}/></span>
        <span className='circulo cementingColor'></span>
        <span>Cementing</span>
      </div>
      {rodape?(
      <div className='flex-10 div-infos d-flex text-white'>
        <div className='d-flex flex-column'>
          <span className='font-bold flex-center'><AccessTimeIcon style={{fontSize: 15}} /> {plataforma.last_update} UTC-3</span>
          <span>{plataforma.update_status}</span>
        </div>
        <div className='d-flex flex-column infos-depth'>
          <div className='d-flex w-100'>
            <span className="font-bold flex-1">Current Bit Depth</span>
            <span className='flex-20'>{plataforma.current_bit_depth}m</span>
          </div>
          <div className="d-flex w-100">
            <span className="font-bold flex-1">Hole Depth</span>
            <span className="flex-20">{plataforma.current_hole_depth}m</span>
          </div>
        </div>
      </div>):<></>}
    </section>
  );
}

export default connect((state: {plataforma: any[], plataformaSections: any[], well: iWell, loading: {dadosplataforma: boolean}}) => {
  return {
    plataforma: state.plataforma, 
    plataformaSections: state.plataformaSections || [],
    well: state.well,
    loading: state.loading.dadosplataforma,
    }
  })(Plataforma);