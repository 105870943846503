import React from "react";
import './NavBarStyle.scss';
import RzxLogo from '../../assets/images/logo1.png';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC<{}> = () =>{
    const navigate = useNavigate()
    
    const navigateToSelectPlatform = () => {
        navigate('/')
    }
    const navigateToSelectWells = () => {
        navigate('/wells')
    }
    const navigateToSelectReports = () => {
        navigate('/reports')
    }
    const navigateToSelectDailyReport = () => {
        navigate('/daily_report')
    }
    // const navigateToDrillingMecanics = () => {
    //     navigate('/drilling_mechanics')
    // }
    const navigateToDrillerScreen = () => {
        navigate('/driller_screen')
    }
    const [params] = useSearchParams()
    return (
        <div className="flex-column">
            <div className="navbar">
                <img src={RzxLogo} width="141" height="56" alt="Rzx"/>
                <ul>
                    <li>
                        <a className="pointer" onClick={navigateToSelectPlatform}> Rigs</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectWells}> Wells</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectReports}> Reports</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectDailyReport}> Daily Report</a>
                    </li>
                    {/* <li>
                        <a className="pointer" onClick={navigateToDrillingMecanics}> Driling Mechanics</a>
                    </li> */}
                    <li>
                        <a className="pointer" onClick={navigateToDrillerScreen}> Driller Screen</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar;