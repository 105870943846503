import React from 'react';

import { Provider as ReduxProvider } from 'react-redux'
import { Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import store from './redux/store'

import './App.scss';
import Plataforma from './pages/Plataforma';
import Login from './pages/Login';
import SelecaoPlataforma from './pages/SelecionarPlataforma/SelecionarPlataforma';
import Usuarios from './pages/Usuarios/Usuarios';
import GestaoPlataforma from './pages/GestaoPlataforma/GestaoPlataforma';
import GestaoPoco from './pages/GestaoPocos/GestaoPocos';
import Reports from './pages/Reports/Reports';
import DailyReport from './pages/DailyReport/DailyReport';
import MailingReports from './pages/MailingManager/MailingReports';
import DrillingMechanics from './pages/DrillingMechanics/DrillingMechanics';
import DrillerScreen from './pages/DrillerScreen/DrillerScreen';

function App() {

  return (
    <CookiesProvider>
      <ReduxProvider store={store} >
        <Routes>
          <Route path="/dashboard" element={<Plataforma />} />
          <Route path="/login" element={<Login />} />
          <Route path="/users" element={<Usuarios />} />
          <Route path="/" element={<SelecaoPlataforma />} />
          <Route path="/platforms" element={<GestaoPlataforma />} />
          <Route path="/wells" element={<GestaoPoco />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/daily_report" element={<DailyReport />} />
          <Route path="/mailing" element={<MailingReports />} />
          {/* <Route path="/drilling_mechanics" element={<DrillingMechanics />} /> */}
          <Route path="/driller_screen" element={<DrillerScreen />} />
        </Routes>
      </ReduxProvider>
    </CookiesProvider>
  );
}

export default App;
